import apiClient from '@/services/axios'
import utilities from '@/services/utilities'

export async function get(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/purchase-orders/list', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function create(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/purchase-orders', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function update(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put('/purchase-orders', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getOne(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/purchase-orders/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function confirm(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put('/purchase-orders/confirm', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function reject(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put('/purchase-orders/reject', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function disable(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.delete(`/purchase-orders/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function delivery(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/purchase-orders-delivered', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export async function updatePaymentMethod(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put('/purchase-orders/update-payment-method', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export async function restoreOrder(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put('/purchase-orders/restore-order', { id })
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export async function getPurchaseOrderSummary() {
	return new Promise((resolve, reject) => {
		return apiClient
			.get('/purchase-orders-summary')
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export async function getPurchaseOrderSummaryFiltered(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/purchase-orders-summary/filtered', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export async function search(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/purchase-orders-search', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export async function setInvoice(order_id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put(`/purchase-orders/set-invoice`, { order_id })
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export async function updateStatus(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put(`/purchase-orders/status`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function uploadFile(field, payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/purchase-orders/file/${field}`, payload, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getFileUrl(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/purchase-orders/file/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
