import apiClient from '@/services/axios'
import utilities from '@/services/utilities'

export async function getAll() {
	return new Promise((resolve, reject) => {
		return apiClient
			.get('/ti/development-requests')
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', []))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getOne(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/ti/development-requests/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', []))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function create(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/ti/development-requests`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', []))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function update(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put(`/ti/development-requests`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', []))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function addComment(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/ti/development-requests/comments`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', []))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
