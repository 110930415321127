import apiClient from '@/services/axios'
import utilities from '@/services/utilities'

export async function get() {
	return new Promise((resolve, reject) => {
		return apiClient
			.get('/taller/clients')
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export async function create(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/taller/clients', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export async function createMotorcycleInformation(clientId, payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/taller/clients/${clientId}/motorcycle-information`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				// console.log('clients::getOne::err-->', err)
				reject(err)
			})
	})
}

export async function updateMotorcycleInformation(clientId, payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put(`/taller/clients/${clientId}/motorcycle-information`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				// console.log('clients::getOne::err-->', err)
				reject(err)
			})
	})
}
