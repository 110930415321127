import apiClient from '@/services/axios'
import utilities from '@/services/utilities'

export async function getOpenServiceOrders() {
	return new Promise((resolve, reject) => {
		return apiClient
			.get('/service-orders/open')
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getFinishedServiceOrders() {
	return new Promise((resolve, reject) => {
		return apiClient
			.get('/service-orders/finished')
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function createPreServiceOrder(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/service-orders/pre-order', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function updatePreServiceOrder(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put('/service-orders/pre-order', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function confirmPreServiceOrder(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/service-orders/pre-order/confirm/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function ingresedPreServiceOrder(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/service-orders/pre-order/ingresed/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getPreServiceOrder() {
	return new Promise((resolve, reject) => {
		return apiClient
			.get('/service-orders/pre-order')
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function cancelPreServiceOrder(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.delete(`/service-orders/pre-order/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function searchPreServiceOrder(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/service-orders/search/pre-order`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
