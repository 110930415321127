import Vue from 'vue'
import Vuex from 'vuex'
import * as api from '@/services/service-administration'
import Swal from 'sweetalert2'
import utilities from '@/services/utilities'
import _ from 'lodash'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		serviceAdministrationList: [],
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET({ commit }) {
			commit('SET_STATE', {
				spinnerLoader: true,
			})
			api
				.get()
				.then((response) => {
					commit('SET_STATE', {
						serviceAdministrationList: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Administración de servicios',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
					})
					commit('SET_STATE', {
						serviceAdministrationList: [],
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		CREATE({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			return new Promise((resolve, reject) => {
				api
					.create(payload)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: 'Administración de servicios',
							description: response.message,
						})
						dispatch('GET')
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Administración de servicios',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al generar el registro'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		DISABLE({ commit, dispatch }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			api
				.disable(id)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: 'Administración de servicios',
						description: response.message,
					})
					dispatch('GET')
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Administración de servicios',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al deshabilitar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		ENABLE({ commit, dispatch }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			api
				.enable(id)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: 'Administración de servicios',
						description: response.message,
					})
					dispatch('GET')
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Administración de servicios',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al habilitar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		UPDATE({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})

			return new Promise((resolve, reject) => {
				api
					.update(payload)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: 'Administración de servicios',
							description: response.message,
						})
						dispatch('GET')
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Administración de servicios',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el registro'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		SEARCH({ commit }, searchText) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})

			api
				.search(searchText)
				.then((response) => {
					commit('SET_STATE', {
						serviceAdministrationList: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Swal.fire({
						title: 'Administración de servicios',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al obtener los registros'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
	},
	getters: {
		serviceAdministrationList: (state) => state.serviceAdministrationList,
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
	},
}
