import Vue from 'vue'
import Vuex from 'vuex'
import * as reports from '@/services/reports'
import moment from 'moment'
import _ from 'lodash'
import utilities from '@/services/utilities'
const dateFormat = 'YYYY-MM-DD'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: 'Obteniendo información',
		purchaseOrders: [],
		filters: {
			defaultDate: [moment().startOf('month').format(dateFormat), moment().startOf('month').add(14, 'd').format(dateFormat)],
			cashier: null,
			paymentMethod: null,
		},
		dataReport: {},
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		RESET_ONE_FILTER({ commit, state }, key) {
			let filters = {
				...state.filters,
			}

			if (key == 'defaultDate') {
				filters.defaultDate = [moment().format(dateFormat), moment().format(dateFormat)]
			} else {
				filters[key] = 'Todos'
			}

			commit('SET_STATE', {
				filters,
			})
		},
		GET_PURCHASE_ORDERS({ commit, dispatch, rootState }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})
			reports
				.purchaseOrders(payload)
				.then((response) => {
					commit('SET_STATE', {
						purchaseOrders: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Reportes',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el reporte'),
					})
					commit('SET_STATE', {
						purchaseOrders: [],
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		SALES({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información...',
			})
			reports
				.sales(payload)
				.then((response) => {
					commit('SET_STATE', {
						dataReport: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Reportes',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el reporte'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
	},
	getters: {
		purchaseOrders: (state) => state.purchaseOrders,
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		filters: (state) => state.filters,
		dataReport: (state) => state.dataReport,
	},
}
