import apiClient from '@/services/axios'
import utilities from '@/services/utilities'

export async function getPaymentMethods() {
	return new Promise((resolve, reject) => {
		return apiClient
			.get('/payment-methods')
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getOnePaymentMethod(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/payment-methods/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
export async function create(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/payment-methods', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function disablePaymentMethod(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/payment-methods/${id}/disable`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function enablePaymentMethod(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/payment-methods/${id}/enable`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
