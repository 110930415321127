import Vue from 'vue'
import Vuex from 'vuex'
import * as quotes from '@/services/quotes'
import utilities from '@/services/utilities'
import Swal from 'sweetalert2'
import router from '@/router'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: 'Obteniendo información',
		quotesList: [],
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		async NEW({ commit, dispatch, state, rootState }) {
			commit('SET_STATE', {
				spinnerLoader: true,
			})
			if (!utilities.objectValidate(rootState, 'serviceAdministration.serviceAdministrationList', false) || !rootState.serviceAdministration.serviceAdministrationList.length) {
				await dispatch('serviceAdministration/GET', null, { root: true })
			}
			router.replace(`/quote/new`)
			commit('SET_STATE', {
				spinnerLoader: false,
			})
		},
		GET({ commit, dispatch }) {
			commit('SET_STATE', {
				spinnerLoader: true,
			})
			quotes
				.get()
				.then((response) => {
					commit('SET_STATE', {
						quotesList: response.data,
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Citas',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
	},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		quotesList: (state) => state.quotesList,
	},
}
