import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import * as clients from '@/services/clients'
import * as tallerClients from '@/services/clients/taller'
import utilities from '@/services/utilities'
import Swal from 'sweetalert2'
import _ from 'lodash'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		actualClient: {},
		clientsList: [],
		searchText: '',
		motorcyclesList: [],
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		CREATE({ commit, dispatch }, { payload, controlTaller }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			let { showControls } = payload
			delete payload.showControls

			return new Promise((resolve, reject) => {
				if (controlTaller) {
					tallerClients
						.create(payload)
						.then((response) => {
							Swal.fire({
								title: 'Clientes',
								text: utilities.objectValidate(response, 'message', 'Registro generado con éxito'),
								icon: 'success',
								confirmButtonText: 'Ok',
							}).then(() => {
								if (showControls) {
									dispatch('GET_ONE', {
										id: response.data.id,
										showControls,
									})
								}
								resolve({
									...payload,
									id: response.data.id,
								})
							})
						})
						.catch((error) => {
							Swal.fire({
								title: 'Clientes',
								text: utilities.objectValidate(error, 'response.data.message', 'Error al generar el registro'),
								icon: 'error',
								confirmButtonText: 'Ok',
							}).then(() => {
								reject()
							})
						})
						.finally(() => {
							commit('SET_STATE', {
								spinnerLoader: false,
							})
						})
				} else {
					clients
						.create(payload)
						.then((response) => {
							Swal.fire({
								title: 'Clientes',
								text: utilities.objectValidate(response, 'message', 'Registro generado con éxito'),
								icon: 'success',
								confirmButtonText: 'Ok',
							}).then(() => {
								if (showControls) {
									dispatch('GET_ONE', {
										id: response.data.id,
										showControls,
									})
								}
								resolve({
									...payload,
									id: response.data.id,
								})
							})
						})
						.catch((error) => {
							Swal.fire({
								title: 'Clientes',
								text: utilities.objectValidate(error, 'response.data.message', 'Error al generar el registro'),
								icon: 'error',
								confirmButtonText: 'Ok',
							}).then(() => {
								reject()
							})
						})
						.finally(() => {
							commit('SET_STATE', {
								spinnerLoader: false,
							})
						})
				}
			})
		},
		UPDATE({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})

			clients
				.update(payload)
				.then((response) => {
					Swal.fire({
						title: 'Clientes',
						text: utilities.objectValidate(response, 'message', 'Registro actualizado con éxito'),
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						commit('SET_STATE', {
							actualClient: {
								...payload,
							},
						})
					})
				})
				.catch((error) => {
					Swal.fire({
						title: 'Clientes',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el registro'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		TOGGLE({ commit, dispatch, state }, id) {
			let { searchText } = state
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			clients
				.toggle(id)
				.then((response) => {
					Swal.fire({
						title: 'Clientes',
						text: utilities.objectValidate(response, 'message', 'Registro actualizado con éxito'),
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						dispatch('SEARCH', searchText)
					})
				})
				.catch((error) => {
					Swal.fire({
						title: 'Clientes',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el registro'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		SEARCH({ commit }, { searchText, searchType }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
				clientsList: [],
				searchText,
			})
			clients
				.search({ searchText, searchType })
				.then((response) => {
					if (response.data.length) {
						commit('SET_STATE', {
							clientsList: _.cloneDeep(response.data),
						})
					} else {
						Vue.prototype.$notification.error({
							message: 'Clientes',
							description: 'No hay registros con los parámetros de búsqueda indicados',
						})
					}
				})
				.catch((error) => {
					Swal.fire({
						title: 'Clientes',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al obtener la información'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		async SEARCH_MOTORCYCLES({ commit }, clientID) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
				motorcyclesList: [],
			})
			return new Promise((resolve, reject) => {
				return clients
					.searchMotorcycles(clientID)
					.then((response) => {
						commit('SET_STATE', {
							motorcyclesList: _.cloneDeep(response.data),
						})
						resolve(response.data)
					})
					.catch((error) => {
						Swal.fire({
							title: 'Clientes',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al obtener la información'),
							icon: 'error',
							confirmButtonText: 'Ok',
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		GET_ONE({ commit }, { id, showControls, redirect = true }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})
			clients
				.getOne(id)
				.then((response) => {
					commit('SET_STATE', {
						actualClient: _.cloneDeep(response.data),
					})
					if (redirect) {
						router.push(`/pos/clients/${id}`)
					}
				})
				.catch((error) => {
					Swal.fire({
						title: 'Clientes',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al obtener la información'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		GET({ commit }, { controlTaller }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})

			if (controlTaller) {
				tallerClients
					.get()
					.then((response) => {
						commit('SET_STATE', {
							clientsList: _.cloneDeep(response.data),
						})
					})
					.catch((error) => {
						Swal.fire({
							title: 'Clientes',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al obtener la información'),
							icon: 'error',
							confirmButtonText: 'Ok',
						})
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			} else {
				clients
					.get()
					.then((response) => {
						commit('SET_STATE', {
							clientsList: _.cloneDeep(response.data),
						})
					})
					.catch((error) => {
						Swal.fire({
							title: 'Clientes',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al obtener la información'),
							icon: 'error',
							confirmButtonText: 'Ok',
						})
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			}
		},
		CREATE_BILLING_INFORMATION({ commit, dispatch }, { clientId, payload }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			clients
				.createBillingInformation(clientId, payload)
				.then((response) => {
					Swal.fire({
						title: 'Clientes',
						text: utilities.objectValidate(response, 'message', 'Registro actualizado con éxito'),
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						dispatch('GET_ONE', {
							id: clientId,
							redirect: false,
						})
					})
				})
				.catch((error) => {
					Swal.fire({
						title: 'Clientes',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar la información'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		UPDATE_BILLING_INFORMATION({ commit, dispatch }, { clientId, payload }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			clients
				.updateBillingInformation(clientId, payload)
				.then((response) => {
					Swal.fire({
						title: 'Clientes',
						text: utilities.objectValidate(response, 'message', 'Registro actualizado con éxito'),
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						dispatch('GET_ONE', {
							id: clientId,
							redirect: false,
						})
					})
				})
				.catch((error) => {
					Swal.fire({
						title: 'Clientes',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar la información'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		DELETE_BILLING_INFORMATION({ commit, dispatch }, { clientId, id }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			clients
				.deleteBillingInformation(id)
				.then((response) => {
					Swal.fire({
						title: 'Clientes',
						text: utilities.objectValidate(response, 'message', 'Registro actualizado con éxito'),
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						dispatch('GET_ONE', {
							id: clientId,
							redirect: false,
						})
					})
				})
				.catch((error) => {
					Swal.fire({
						title: 'Clientes',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar la información'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		CREATE_MOTORCYCLE_INFORMATION({ commit, dispatch }, { clientId, payload, controlTaller }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})

			return new Promise((resolve, reject) => {
				if (controlTaller) {
					tallerClients
						.createMotorcycleInformation(clientId, payload)
						.then((response) => {
							Swal.fire({
								title: 'Clientes',
								text: utilities.objectValidate(response, 'message', 'Registro actualizado con éxito'),
								icon: 'success',
								confirmButtonText: 'Ok',
							}).then(() => {
								dispatch('SEARCH_MOTORCYCLES', clientId)
								resolve(response)
							})
						})
						.catch((error) => {
							Swal.fire({
								title: 'Clientes',
								text: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar la información'),
								icon: 'error',
								confirmButtonText: 'Ok',
							}).then(() => {
								reject(error)
							})
						})
						.finally(() => {
							commit('SET_STATE', {
								spinnerLoader: false,
							})
						})
				} else {
					clients
						.createMotorcycleInformation(clientId, payload)
						.then((response) => {
							Swal.fire({
								title: 'Clientes',
								text: utilities.objectValidate(response, 'message', 'Registro actualizado con éxito'),
								icon: 'success',
								confirmButtonText: 'Ok',
							}).then(() => {
								dispatch('GET_ONE', {
									id: clientId,
									redirect: false,
								})
								resolve(response)
							})
						})
						.catch((error) => {
							Swal.fire({
								title: 'Clientes',
								text: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar la información'),
								icon: 'error',
								confirmButtonText: 'Ok',
							}).then(() => {
								reject(error)
							})
						})
						.finally(() => {
							commit('SET_STATE', {
								spinnerLoader: false,
							})
						})
				}
			})
		},
		UPDATE_MOTORCYCLE_INFORMATION({ commit, dispatch }, { clientId, payload, controlTaller }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})

			if (controlTaller) {
				tallerClients
					.updateMotorcycleInformation(clientId, payload)
					.then((response) => {
						Swal.fire({
							title: 'Clientes',
							text: utilities.objectValidate(response, 'message', 'Registro actualizado con éxito'),
							icon: 'success',
							confirmButtonText: 'Ok',
						}).then(() => {
							dispatch('GET_ONE', {
								id: clientId,
								redirect: false,
							})
						})
					})
					.catch((error) => {
						Swal.fire({
							title: 'Clientes',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar la información'),
							icon: 'error',
							confirmButtonText: 'Ok',
						})
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			} else {
				clients
					.updateMotorcycleInformation(clientId, payload)
					.then((response) => {
						Swal.fire({
							title: 'Clientes',
							text: utilities.objectValidate(response, 'message', 'Registro actualizado con éxito'),
							icon: 'success',
							confirmButtonText: 'Ok',
						}).then(() => {
							dispatch('GET_ONE', {
								id: clientId,
								redirect: false,
							})
						})
					})
					.catch((error) => {
						Swal.fire({
							title: 'Clientes',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar la información'),
							icon: 'error',
							confirmButtonText: 'Ok',
						})
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			}
		},
		DELETE_MOTORCYCLE_INFORMATION({ commit, dispatch }, { clientId, id }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			clients
				.deleteMotorcycleInformation(id)
				.then((response) => {
					Swal.fire({
						title: 'Clientes',
						text: utilities.objectValidate(response, 'message', 'Registro actualizado con éxito'),
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						dispatch('GET_ONE', {
							id: clientId,
							redirect: false,
						})
					})
				})
				.catch((error) => {
					Swal.fire({
						title: 'Clientes',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar la información'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
	},
	getters: {
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		spinnerLoader: (state) => state.spinnerLoader,
		actualClient: (state) => state.actualClient,
		clientsList: (state) => state.clientsList,
		motorcyclesList: (state) => state.motorcyclesList,
	},
}
