import apiClient from '@/services/axios'
import utilities from '@/services/utilities'

export async function getAll() {
	return new Promise((resolve, reject) => {
		return apiClient
			.get('/debts-to-pay')
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function get(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/debts-to-pay/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function update(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put('/debts-to-pay', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function toggle(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put('/debts-to-pay/toggle')
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
