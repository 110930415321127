import apiClient from '@/services/axios'
import utilities from '@/services/utilities'

export async function get() {
	return new Promise((resolve, reject) => {
		return apiClient
			.get('/surveys')
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getOne(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/surveys/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function save(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/surveys`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getResults(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/surveys/results`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getAnswers(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/surveys/results/answers/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getResultsByUser(user_id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/surveys/results/${user_id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
