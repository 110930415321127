import apiClient from '@/services/axios'
import utilities from '@/services/utilities'

export async function getSuppliers() {
	return new Promise((resolve, reject) => {
		return apiClient
			.get('/suppliers')
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getOneSupplier(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/suppliers/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function createSupplier(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/suppliers`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function updateSupplier(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put(`/suppliers`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function search(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/suppliers/search`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function deleteRecord(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put(`/suppliers/delete`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function uploadFile(field, payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/suppliers/file/${field}`, payload, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getFileUrl(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/suppliers/file/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
