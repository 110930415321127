import Vue from 'vue'
import Vuex from 'vuex'
import * as cashflowActions from '@/services/cashflow-actions'
import utilities from '@/services/utilities'
import Swal from 'sweetalert2'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		cashflowActionsList: [],
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})
			cashflowActions
				.get(payload)
				.then((response) => {
					//
					commit('SET_STATE', {
						cashflowActionsList: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Productos',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el servicio'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		CREATE({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Generando registro',
			})
			cashflowActions
				.create(payload)
				.then((response) => {
					//
					Swal.fire({
						title: 'Acciones de flujos de caja',
						text: utilities.objectValidate(response, 'message', 'Registro generado con éxito'),
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						dispatch('GET')
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Productos',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al generar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		UPDATE({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando registro',
			})
			cashflowActions
				.update(payload)
				.then((response) => {
					//
					Swal.fire({
						title: 'Acciones de flujos de caja',
						text: utilities.objectValidate(response, 'message', 'Registro actualizado con éxito'),
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						dispatch('GET')
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Productos',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
	},
	getters: {
		cashflowActionsList: (state) => state.cashflowActionsList,
	},
}
