import apiClient from '@/services/axios'
import utilities from '@/services/utilities'

export async function get() {
	return new Promise((resolve, reject) => {
		return apiClient
			.get('/users')
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
export async function getOne(user_id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/users/${user_id}`)
			.then((response) => {
				if (response.data) {
					resolve(utilities.objectValidate(response, 'data', {}))
				}
				return false
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
export async function create(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/users', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function update(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put(`/users/${payload.user_id}`, payload)
			.then((response) => {
				if (response.data) {
					resolve(utilities.objectValidate(response, 'data', {}))
				}
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
export async function disable(user_id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put(`/users/disable/${user_id}`)
			.then((response) => {
				if (response.data) {
					resolve(utilities.objectValidate(response, 'data', {}))
				}
				return false
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function enable(user_id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put(`/users/enable/${user_id}`)
			.then((response) => {
				if (response.data) {
					resolve(utilities.objectValidate(response, 'data', {}))
				}
				return false
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getMenu() {
	return new Promise((resolve, reject) => {
		return apiClient
			.get('/menu')
			.then((response) => {
				if (response.data) {
					resolve(utilities.objectValidate(response, 'data', {}))
				}
				return false
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function updatePermissions(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put('/users/permissions/update', payload)
			.then((response) => {
				if (response.data) {
					resolve(utilities.objectValidate(response, 'data', {}))
				}
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export async function requestAuthorization(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/users/request-authorization`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
