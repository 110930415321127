import apiClient from '@/services/axios'
import utilities from '@/services/utilities'

export async function ticketByCashier(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/cashflow/by-cashier/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				// console.log(err)
				reject(err)
			})
	})
}

export async function createByCashier() {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/cashflow/by-cashier')
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				// console.log(err)
				reject(err)
			})
	})
}

export async function getFlow(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/cashflow/getCashFlow', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				// console.log(err)
				reject(err)
			})
	})
}

export async function get(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/cashflow/general', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				// console.log(err)
				reject(err)
			})
	})
}

export async function create(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/cash-flow', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				// console.log(err)
				reject(err)
			})
	})
}

export async function update(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put('/cash-flow', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				// console.log(err)
				reject(err)
			})
	})
}

export async function confirm(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put('/cash-flow/confirm', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				// console.log(err)
				reject(err)
			})
	})
}

export async function getSummary(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/cash-flow/summary', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				// console.log(err)
				reject(err)
			})
	})
}

export async function uploadFile(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put('/cash-flow/file', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export async function spendingAuthorizations(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/cashflow/spending-authorization', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export async function setSpendingAuthorizations(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put('/cashflow/spending-authorization', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export async function getFileUrl(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/cashflow/file/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function swapMoney(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/cashflow/swap-money`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
