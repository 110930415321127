import apiClient from '@/services/axios'
import store from 'store'
import utilities from '@/services/utilities'

export async function login(email, password) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/users/login', {
				email,
				password,
			})
			.then((response) => {
				if (utilities.objectValidate(response, 'data', false)) {
					const { token } = response.data
					if (token) {
						store.set('accessToken', token)
					}
					resolve(utilities.objectValidate(response, 'data', {}))
				} else {
					reject({})
				}
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export async function register(payload) {
	return apiClient
		.post('/users', payload)
		.then((response) => {
			// console.log('apiClient::register::response-->', response)
			if (response) {
				const { token } = response.data
				if (token) {
					store.set('personalInformation', JSON.stringify(response.data))
					store.set('accessToken', token)
				}
				store.set('currentStep', 1)
				return response.data
			}
			return false
		})
		.catch((err) => console.log(err))
}

export async function currentAccount() {
	return apiClient
		.get('/users/auth/current-account/')
		.then((response) => {
			// console.log('currentAccount::response-->', response)
			if (response) {
				const { token } = response.data
				if (token) {
					store.set('personalInformation', JSON.stringify(response.data.data))
					store.set('accessToken', token)
				}
				return response.data.data
			}
			return false
		})
		.catch((err) => console.log(err))
}

export async function logout() {
	return apiClient
		.get('/users/auth/logout')
		.then(() => {
			localStorage.clear()
			return true
		})
		.catch((err) => console.log(err))
}

export async function updateUser(payload) {
	// console.log('payload')
	return apiClient
		.put(`/users/${payload.user_id}`, payload)
		.then((response) => {
			// console.log('actions:users:updateUser:response-->', response.data)
			if (response) {
				return response.data
			}
			return false
		})
		.catch((err) => console.log(err))
}

// Profiles
export async function getProfiles() {
	// console.log('actions:users:getProfiles', brand_id)
	return apiClient
		.get(`/catalogs/profiles`)
		.then((response) => {
			// console.log('actions:users:getProfiles:response', response.data)
			if (response.data) {
				return response.data
			}
			return false
		})
		.catch((err) => console.log(err))
}

export async function updatePassword(payload) {
	return apiClient
		.post('/users/updatePassword', payload)
		.then((response) => {
			return response.data
		})
		.catch((err) => console.log(err))
}

export async function recoveryPassword(payload) {
	return apiClient
		.post('/users/recoveryPassword', payload)
		.then((response) => {
			return response.data
		})
		.catch((err) => console.log(err))
}
