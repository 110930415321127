import apiClient from '@/services/axios'
import utilities from '@/services/utilities'

export async function create(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/clients', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export async function update(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put('/clients', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export async function toggle(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.delete(`/clients/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export async function search(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(payload.searchType ? `/taller/clients/search` : '/clients/search', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export async function searchMotorcycles(clientID) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/clients/search-motorcycles/${clientID}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export async function get() {
	return new Promise((resolve, reject) => {
		return apiClient
			.get('/clients')
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export async function getOne(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/clients/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				// console.log('clients::getOne::err-->', err)
				reject(err)
			})
	})
}

export async function createBillingInformation(clientId, payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/clients/${clientId}/billing-information`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				// console.log('clients::getOne::err-->', err)
				reject(err)
			})
	})
}

export async function updateBillingInformation(clientId, payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put(`/clients/${clientId}/billing-information`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				// console.log('clients::getOne::err-->', err)
				reject(err)
			})
	})
}

export async function deleteBillingInformation(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.delete(`/clients/billing-information/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				// console.log('clients::getOne::err-->', err)
				reject(err)
			})
	})
}

export async function createMotorcycleInformation(clientId, payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/clients/${clientId}/motorcycle-information`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				// console.log('clients::getOne::err-->', err)
				reject(err)
			})
	})
}

export async function updateMotorcycleInformation(clientId, payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put(`/clients/${clientId}/motorcycle-information`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				// console.log('clients::getOne::err-->', err)
				reject(err)
			})
	})
}

export async function deleteMotorcycleInformation(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.delete(`/clients/motorcycle-information/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				// console.log('clients::getOne::err-->', err)
				reject(err)
			})
	})
}
