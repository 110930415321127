import apiClient from '@/services/axios'
import utilities from '@/services/utilities'

export async function get(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/orders', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function create(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/orders/create', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function updateStatus(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put('/orders/status', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function generatePDF(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/orders/${id}/pdf`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getAddresses(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/orders/addresses`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
