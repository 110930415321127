import apiClient from '@/services/axios'
import utilities from '@/services/utilities'

export async function get() {
	return new Promise((resolve, reject) => {
		return apiClient
			.get('/quotations')
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getQuotation(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/quotations/download/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getOne(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/quotations/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function create(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/quotations', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function update(id, payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put(`/quotations/${id}`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
