export const getMenuData = {
	admin: [
		{
			category: true,
			title: 'Menú Principal',
		},
		{
			title: 'Dashboard',
			key: 'dashboards',
			url: '/',
			icon: 'fa fa-home',
		},
		{
			title: 'Ordenes de compra',
			key: 'purchase-orders',
			icon: 'fa fa-shopping-cart',
			children: [
				{
					title: 'Resumen',
					key: 'purchase-orders_summary',
					url: '/purchase-orders/summary',
					icon: 'fa fa-list-alt',
				},
				{
					title: 'Buscador',
					key: 'purchase-orders_search',
					url: '/purchase-orders/search',
					icon: 'fa fa-search',
				},
				{
					title: 'En espera',
					key: 'purchase-orders_pending',
					url: '/purchase-orders/pending',
					icon: 'fa fa-clock-o',
				},
				{
					title: 'En proceso',
					key: 'purchase-orders_InProgress',
					url: '/purchase-orders/in-progress',
					icon: 'fa fa-spinner',
				},
				{
					title: 'Entregado',
					key: 'purchase-orders_Delivered',
					url: '/purchase-orders/delivered',
					icon: 'fa fa-handshake-o',
				},
				{
					title: 'Rechazado',
					key: 'purchase-orders_Rejected',
					url: '/purchase-orders/rejected',
					icon: 'fa fa-ban',
				},
				{
					title: 'Nueva orden',
					key: 'purchase-orders_create',
					url: '/purchase-orders/create',
					icon: 'fa fa-plus',
				},
			],
		},
		{
			title: 'Almacén',
			key: 'warehouse',
			icon: 'fa fa-terminal',
			children: [
				{
					title: 'Productos',
					key: 'warehouse_products',
					url: '/warehouse/products',
				},
				{
					title: 'Operaciones',
					key: 'warehouse_operations',
					url: '/warehouse/operations',
				},
			],
		},
		{
			title: 'Catálogos',
			key: 'catalogs',
			icon: 'fa fa-list-alt',
			children: [
				{
					title: 'Proveedores',
					key: 'administration_Suppliers',
					url: '/catalogs/suppliers',
					icon: 'fa fa-users',
				},
				{
					title: 'Lista de servicios ',
					key: 'administration_ServiceAdministration',
					url: '/catalogs/service-administration',
					icon: 'fa fa-bars',
				},
				{
					title: 'Marca Moto',
					key: 'administration_MotorcycleBrand',
					url: '/catalogs/motorcycle-brands',
					icon: 'fa fa-motorcycle',
				},
				{
					title: 'Modelo Moto',
					key: 'administration_MotorcycleModel',
					url: '/catalogs/motorcycle-models',
					icon: 'fa fa-motorcycle',
				},
				{
					title: 'Áreas de Negocio',
					key: 'system_business_areas',
					url: '/catalogs/business-areas',
					icon: 'fa fa-building',
				},
				{
					title: 'Tipos de gastos',
					key: 'system_expense_types',
					url: '/catalogs/expense-types',
					icon: 'fa fa-money',
				},
				{
					title: 'Unidades de medida',
					key: 'system_measurement_units',
					url: '/catalogs/measurement-units',
					icon: 'fa fa-tachometer',
				},
				{
					title: 'Instituciones Bancarias',
					key: 'banking_institutions',
					url: '/catalogs/banking-institutions',
					icon: 'fa fa-university',
				},
				{
					title: 'Categorías de Productos',
					key: 'products_categories',
					url: '/catalogs/products-categories',
					icon: 'fa fa-arrows',
				},
			],
		},
		{
			title: 'Operación',
			key: 'operation',
			icon: 'fa fa-gears',
			children: [
				{
					title: 'Cortes',
					key: 'operations_cash_flow',
					url: '/operations/cash-flow',
					icon: 'fa fa-money',
				},
				{
					title: 'Ingresos',
					key: 'operations_incomes',
					url: '/operations/incomes',
					icon: 'fa fa-compress',
				},
			],
		},
		{
			category: true,
			title: 'Administración',
		},
		{
			title: 'Tabulador de precios',
			key: 'administration_PriceTab',
			url: '/operations/price-tab',
			icon: 'fa fa-list-ol',
		},
		{
			title: 'Mapa cotizador',
			key: 'administration_map',
			url: '/administration/quote',
			icon: 'fa fa-map',
		},
		{
			category: true,
			title: 'Sistema',
		},
		{
			title: 'Mi perfil',
			key: 'system_profile',
			url: '/profile',
			icon: 'fa fa-user',
		},
		{
			title: 'Usuarios',
			key: 'system_users',
			url: '/system/users',
			icon: 'fa fa-users',
		},
		{
			category: true,
			title: 'Reportes',
		},
		{
			title: 'Reporte de compras',
			key: 'reports_purchase_orders',
			icon: 'fe fe-pie-chart',
			url: '/reports/purchase-orders',
		},
	],
	user: [
		{
			category: true,
			title: 'Menú Principal',
		},
		{
			title: 'Dashboard',
			key: 'dashboards',
			url: '/',
			icon: 'fa fa-home',
		},
		{
			title: 'Ordenes de compra',
			key: 'purchase-orders',
			icon: 'fa fa-shopping-cart',
			children: [
				{
					title: 'En espera',
					key: 'administration_PurchaseOrdersPending',
					url: '/purchase-orders/pending',
					icon: 'fa fa-clock-o',
				},
				{
					title: 'En proceso',
					key: 'administration_PurchaseOrdersInProgress',
					url: '/purchase-orders/in-progress',
					icon: 'fa fa-spinner',
				},
				{
					title: 'Nueva orden',
					key: 'administration_PurchaseOrders',
					url: '/purchase-orders/create',
					icon: 'fa fa-plus',
				},
			],
		},
		{
			title: 'Catálogos',
			key: 'catalogs',
			icon: 'fa fa-list-alt',
			children: [
				{
					title: 'Proveedores',
					key: 'administration_Suppliers',
					url: '/catalogs/suppliers',
					icon: 'fa fa-users',
				},
				{
					title: 'Lista de servicios ',
					key: 'administration_ServiceAdministration',
					url: '/catalogs/service-administration',
					icon: 'fa fa-bars',
				},
				{
					title: 'Marca Moto',
					key: 'administration_MotorcycleBrand',
					url: '/catalogs/motorcycle-brands',
					icon: 'fa fa-motorcycle',
				},
				{
					title: 'Modelo Moto',
					key: 'administration_MotorcycleModel',
					url: '/catalogs/motorcycle-models',
					icon: 'fa fa-motorcycle',
				},
			],
		},
		{
			category: true,
			title: 'Administración',
		},
		{
			title: 'Tabulador de precios',
			key: 'administration_PriceTab',
			url: '/operations/price-tab',
			icon: 'fa fa-list-ol',
		},
		{
			title: 'Mapa cotizador',
			key: 'administration_map',
			url: '/administration/quote',
			icon: 'fa fa-map',
		},
		{
			title: 'Operación',
			key: 'operation',
			icon: 'fa fa-gears',
			children: [
				{
					title: 'Cortes',
					key: 'operations_cash_flow',
					url: '/operations/cash-flow',
					icon: 'fa fa-money',
				},
				{
					title: 'Ingresos',
					key: 'operations_incomes',
					url: '/operations/incomes',
					icon: 'fa fa-compress',
				},
			],
		},
		{
			category: true,
			title: 'Sistema',
		},
		{
			title: 'Mi perfil',
			key: 'system_profile',
			url: '/profile',
			icon: 'fa fa-user',
		},
	],
}
