import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import * as api from '@/services/orders'
import utilities from '@/services/utilities'
import Swal from 'sweetalert2'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		ordersList: [],
		filters: {
			status: '0',
		},
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información...',
			})
			api
				.get(payload)
				.then((response) => {
					commit('SET_STATE', {
						ordersList: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Swal.fire({
						title: 'Pedidos',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al consultar los registros. Intenta de nuevo.'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		async CREATE({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Generando registro',
			})
			return new Promise((resolve, reject) => {
				api
					.create(payload)
					.then((response) => {
						resolve(response.data)
					})
					.catch((error) => {
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async UPDATE_STATUS({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando registro',
			})
			return new Promise((resolve, reject) => {
				api
					.updateStatus(payload)
					.then((response) => {
						dispatch('GET_ONE', payload.id)
						resolve(response)
					})
					.catch((error) => {
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async GENERATE_PDF({ commit }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})
			return new Promise((resolve, reject) => {
				api
					.generatePDF(id)
					.then((response) => {
						resolve(response)
					})
					.catch((error) => {
						Swal.fire({
							title: 'Pedidos',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al obtener el registro. Intenta de nuevo.'),
							icon: 'error',
							confirmButtonText: 'Ok',
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async GET_ADDRESSES({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})
			return new Promise((resolve, reject) => {
				api
					.getAddresses(payload)
					.then((response) => {
						resolve(response)
					})
					.catch((error) => {
						Swal.fire({
							title: 'Pedidos',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al obtener el registro. Intenta de nuevo.'),
							icon: 'error',
							confirmButtonText: 'Ok',
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
	},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		ordersList: (state) => state.ordersList,
		filters: (state) => state.filters,
	},
}
