import Vue from 'vue'
import Vuex from 'vuex'
import * as api from '@/services/surveys'
import _ from 'lodash'
import utilities from '@/services/utilities'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		surveysList: [],
		actualSurvey: {},
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		async GET({ commit }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})

			return new Promise((resolve, reject) => {
				api
					.get()
					.then((response) => {
						let payload = _.cloneDeep(utilities.objectValidate(response, 'data', {}))
						commit('SET_STATE', {
							surveysList: payload,
						})
						resolve(payload)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Error',
							description: 'Error al obtener el listado de encuestas',
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async GET_ONE({ commit }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})

			return new Promise((resolve, reject) => {
				api
					.getOne(id)
					.then((response) => {
						let payload = _.cloneDeep(utilities.objectValidate(response, 'data', {}))
						commit('SET_STATE', {
							actualSurvey: payload,
						})
						resolve(payload)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Error',
							description: 'Error al obtener el listado de encuestas',
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async SAVE({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Generando registro',
			})

			return new Promise((resolve, reject) => {
				api
					.save(payload)
					.then((response) => {
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Error',
							description: 'Error al generar el registro de encuesta',
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async GET_RESULTS({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo resultados',
			})

			return new Promise((resolve, reject) => {
				api
					.getResults(payload)
					.then((response) => {
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Error',
							description: 'Error al obtener resultados de la encuesta',
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async GET_RESULTS_BY_USER({ commit }, { user_id }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo resultados',
			})

			return new Promise((resolve, reject) => {
				api
					.getResultsByUser(user_id)
					.then((response) => {
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Error',
							description: 'Error al obtener resultados de la encuesta',
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async GET_ANSWERS({ commit }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo resultados',
			})

			return new Promise((resolve, reject) => {
				api
					.getAnswers(id)
					.then((response) => {
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Error',
							description: 'Error al obtener resultados de la encuesta',
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
	},
	getters: {
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		spinnerLoader: (state) => state.spinnerLoader,
		surveysList: (state) => state.surveysList,
		actualSurvey: (state) => state.actualSurvey,
	},
}
