import Vue from 'vue'
import Vuex from 'vuex'
import * as api from '@/services/tireQuoter'
import utilities from '@/services/utilities'
import _ from 'lodash'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		list: [],
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET({ commit }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})
			api
				.get()
				.then((response) => {
					commit('SET_STATE', {
						list: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Cotizador de llantas',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar la base de datos'),
					})
					commit('SET_STATE', {
						apiList: [],
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
	},

	getters: {
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		spinnerLoader: (state) => state.spinnerLoader,
		list: (state) => state.list,
	},
}
