import Vue from 'vue'
import Vuex from 'vuex'
import * as api from '@/services/developmentRequests'
import utilities from '@/services/utilities'
import _ from 'lodash'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		developmentRequestsList: [],
		actualRecord: {},
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET({ commit }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})
			api
				.getAll()
				.then((response) => {
					//
					commit('SET_STATE', {
						developmentRequestsList: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Sistemas & TI',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el servicio'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		GET_ONE({ commit }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})
			api
				.getOne(id)
				.then((response) => {
					//
					commit('SET_STATE', {
						actualRecord: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Sistemas & TI',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el servicio'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		CREATE({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			api
				.create(payload)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: 'Sistemas & TI',
						description: utilities.objectValidate(response, 'message', 'Registro generado con éxito'),
					})
					dispatch('GET_ONE', response.data.id)
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Sistemas & TI',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al generar el servicio'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		UPDATE({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			api
				.update(payload)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: 'Sistemas & TI',
						description: utilities.objectValidate(response, 'message', 'Registro generado con éxito'),
					})
					dispatch('GET_ONE', payload.id)
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Sistemas & TI',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el servicio'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		ADD_COMMENT({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			api
				.addComment(payload)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: 'Sistemas & TI',
						description: utilities.objectValidate(response, 'message', 'Registro generado con éxito'),
					})
					dispatch('GET_ONE', payload.id)
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Sistemas & TI',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el servicio'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
	},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		developmentRequestsList: (state) => state.developmentRequestsList,
		actualRecord: (state) => state.actualRecord,
	},
}
