import Vue from 'vue'
import Vuex from 'vuex'
import * as serviceOrders from '@/services/service-orders'
import utilities from '@/services/utilities'
import _ from 'lodash'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		serviceOrdersList: [],
		enableWizardModal: false,
		quotationID: '',
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		async GET_OPEN_SERVICE_ORDERS({ commit }, payload) {
			if (utilities.objectValidate(payload, 'globalLoader', false)) {
				commit(
					'SET_STATE',
					{
						spinnerLoader: true,
						spinnerLoaderLabel: 'Obteniendo información',
					},
					{ root: true },
				)
			} else {
				commit('SET_STATE', {
					spinnerLoader: true,
					spinnerLoaderLabel: 'Obteniendo información',
				})
			}

			return new Promise((resolve, reject) => {
				return serviceOrders
					.getOpenServiceOrders()
					.then((response) => {
						commit('SET_STATE', {
							serviceOrdersList: _.cloneDeep(response.data),
						})
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Ordenes de Servicio',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar las ordenes de servicio'),
						})
						reject(error)
					})
					.finally(() => {
						if (utilities.objectValidate(payload, 'globalLoader', false)) {
							commit(
								'SET_STATE',
								{
									spinnerLoader: true,
									spinnerLoaderLabel: 'Obteniendo información',
								},
								{ root: true },
							)
						} else {
							commit('SET_STATE', {
								spinnerLoader: false,
							})
						}
					})
			})
		},
		async GET_FINISHED_SERVICE_ORDERS({ commit }, payload) {
			if (utilities.objectValidate(payload, 'globalLoader', false)) {
				commit(
					'SET_STATE',
					{
						spinnerLoader: true,
						spinnerLoaderLabel: 'Obteniendo información',
					},
					{ root: true },
				)
			} else {
				commit('SET_STATE', {
					spinnerLoader: true,
					spinnerLoaderLabel: 'Obteniendo información',
				})
			}

			return new Promise((resolve, reject) => {
				return serviceOrders
					.getFinishedServiceOrders()
					.then((response) => {
						commit('SET_STATE', {
							serviceOrdersList: _.cloneDeep(response.data),
						})
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Ordenes de Servicio',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar las ordenes de servicio'),
						})
						reject(error)
					})
					.finally(() => {
						if (utilities.objectValidate(payload, 'globalLoader', false)) {
							commit(
								'SET_STATE',
								{
									spinnerLoader: true,
									spinnerLoaderLabel: 'Obteniendo información',
								},
								{ root: true },
							)
						} else {
							commit('SET_STATE', {
								spinnerLoader: false,
							})
						}
					})
			})
		},
		async CREATE_PRE_SERVICE_ORDER({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Generando registro',
			})

			return new Promise((resolve, reject) => {
				serviceOrders
					.createPreServiceOrder(payload)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: 'Ordenes de Servicio',
							description: utilities.objectValidate(response, 'message', 'Se ha generado el registro de manera correcta'),
						})
						dispatch('GET_PRE_SERVICE_ORDER')
						commit('SET_STATE', {
							enableWizardModal: false,
							quotationID: '',
						})
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Ordenes de Servicio',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar las ordenes de servicio'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async UPDATE_PRE_SERVICE_ORDER({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando registro',
			})

			return new Promise((resolve, reject) => {
				serviceOrders
					.updatePreServiceOrder(payload)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: 'Ordenes de Servicio',
							description: utilities.objectValidate(response, 'message', 'Se ha actualizado el registro de manera correcta'),
						})
						dispatch('GET_PRE_SERVICE_ORDER')
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Ordenes de Servicio',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar las ordenes de servicio'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async CONFIRM_PRE_SERVICE_ORDER({ commit, dispatch }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando registro',
			})

			return new Promise((resolve, reject) => {
				serviceOrders
					.confirmPreServiceOrder(id)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: 'Ordenes de Servicio',
							description: utilities.objectValidate(response, 'message', 'Se ha confirmado la cita de manera correcta'),
						})
						dispatch('GET_PRE_SERVICE_ORDER')
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Ordenes de Servicio',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al confirmar la cita.'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async INGRESED_PRE_SERVICE_ORDER({ commit, dispatch }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando registro',
			})

			return new Promise((resolve, reject) => {
				serviceOrders
					.ingresedPreServiceOrder(id)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: 'Ordenes de Servicio',
							description: utilities.objectValidate(response, 'message', 'Se ha confirmado el ingreso de la motocicleta.'),
						})
						dispatch('GET_PRE_SERVICE_ORDER')
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Ordenes de Servicio',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al ingresar la cita.'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async CANCEL_PRE_SERVICE_ORDER({ commit, dispatch }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Cancelando registro',
			})

			return new Promise((resolve, reject) => {
				serviceOrders
					.cancelPreServiceOrder(id)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: 'Ordenes de Servicio',
							description: utilities.objectValidate(response, 'message', 'Se ha cancelado la cita indicada.'),
						})
						dispatch('GET_PRE_SERVICE_ORDER')
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Ordenes de Servicio',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al cancelar la cita.'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		GET_PRE_SERVICE_ORDER({ commit }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo registros',
				serviceOrdersList: [],
			})

			serviceOrders
				.getPreServiceOrder()
				.then((response) => {
					commit('SET_STATE', {
						serviceOrdersList: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Ordenes de Servicio',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar las ordenes de servicio'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		async SEARCH_PRE_SERVICE_ORDER({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo registros',
			})

			return new Promise((resolve, reject) => {
				serviceOrders
					.searchPreServiceOrder(payload)
					.then((response) => {
						resolve(response.data)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Ordenes de Servicio',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar las ordenes de servicio'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
	},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		serviceOrdersList: (state) => state.serviceOrdersList,
		enableWizardModal: (state) => state.enableWizardModal,
		quotationID: (state) => state.quotationID,
	},
}
