import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import * as jwt from '@/services/jwt'
import * as api from '@/services/users'
import Swal from 'sweetalert2'
import utilities from '@/services/utilities'

const mapAuthProviders = {
	jwt: {
		login: jwt.login,
		register: jwt.register,
		currentAccount: jwt.currentAccount,
		logout: jwt.logout,
		recoveryPassword: jwt.recoveryPassword,
		updatePassword: jwt.updatePassword,
		updateUser: jwt.updateUser,
	},
}

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		authorized: process.env.VUE_APP_AUTHENTICATED || false, // false is default value
		loading: false,
		spinnerLoaderLabel: '',
		avatar_image: null,
		mobile_number: '',
		role: 'user',
		is_admin: false,
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
		SET_PERSONAL_INFORMATION(state, payload) {
			Object.assign(state.personalInformation, {
				...payload,
			})
		},
	},
	actions: {
		LOGIN({ commit, dispatch, rootState }, { payload }) {
			const { email, password } = payload
			commit('SET_STATE', {
				loading: true,
			})

			const login = mapAuthProviders[rootState.settings.authProvider].login
			login(email, password)
				.then((success) => {
					dispatch('LOAD_CURRENT_ACCOUNT')
					Vue.prototype.$notification.success({
						message: 'Bienvenido(a)',
						description: '¡Has iniciado sesión correctamente!',
					})
				})
				.catch((error) => {
					Swal.fire({
						icon: 'error',
						title: 'Inicio de sesión',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al iniciar sesión'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						loading: false,
					})
				})
		},
		REGISTER({ commit, dispatch, rootState }, { payload }) {
			const { name, first_lastname, second_lastname, email, password, curp, phone_number, mobile_number } = payload
			const payloadRegister = { name, first_lastname, second_lastname, email, password, curp, phone_number, mobile_number }
			commit('SET_STATE', {
				loading: true,
				spinnerLoaderLabel: 'Generando registro...',
			})
			const register = mapAuthProviders[rootState.settings.authProvider].register
			return register(payloadRegister).then((response) => {
				// console.log('register-->', response)
				if (response) {
					commit('SET_STATE', {
						loading: false,
					})
					Vue.prototype.$notification.success({
						message: 'USUARIOS DEL SISTEMA',
						description: response.message,
					})
					return response.data
				} else {
					commit('SET_STATE', {
						loading: false,
					})
					return false
				}
			})
		},
		LOAD_CURRENT_ACCOUNT({ commit, rootState, dispatch }) {
			commit('SET_STATE', {
				loading: true,
			})
			const currentAccount = mapAuthProviders[rootState.settings.authProvider].currentAccount
			currentAccount().then((response) => {
				if (response) {
					dispatch('users/GET_MENU', {}, { root: true })
					commit('SET_STATE', {
						...response,
						authorized: true,
						role: response.is_admin ? 'admin' : 'user',
					})
				}
				commit('SET_STATE', {
					loading: false,
				})
			})
		},
		LOGOUT({ commit, rootState }) {
			commit('SET_STATE', {
				authorized: false,
			})
			setTimeout(() => {
				localStorage.clear()
				router.push('/auth/login')
			}, 100)
		},
		RECOVERY_PASSWORD({ commit, dispatch, rootState }, { payload }) {
			const { email } = payload
			commit('SET_STATE', {
				loading: true,
			})

			const apiCall = mapAuthProviders[rootState.settings.authProvider].recoveryPassword
			apiCall(payload).then((success) => {
				commit('SET_STATE', {
					loading: false,
				})
				Swal.fire({
					icon: 'success',
					title: 'Recuperación de contraseña',
					text: success.message,
				}).then(() => {
					router.push('/auth/login')
				})
			})
		},
		UPDATE_PASSWORD({ commit, dispatch, rootState }, { payload }) {
			const { password, token } = payload
			commit('SET_STATE', {
				loading: true,
			})

			const apiCall = mapAuthProviders[rootState.settings.authProvider].updatePassword
			apiCall({ password, token }).then((success) => {
				commit('SET_STATE', {
					loading: false,
				})
				Swal.fire({
					icon: 'success',
					title: 'Recuperación de contraseña',
					text: success.message,
				}).then(() => {
					router.push('/auth/login')
				})
			})
		},
		UPDATE_PERSONAL_INFORMATION({ commit, dispatch, rootState }, { payload }) {
			const { name, first_lastname, second_lastname, username, email, mobile_number, password, user_id } = payload
			const payloadUpdate = { name, first_lastname, second_lastname, username, email, mobile_number, password, user_id }
			commit('SET_STATE', {
				loading: true,
				spinnerLoaderLabel: 'Actualizando información personal',
			})
			const updateUser = mapAuthProviders[rootState.settings.authProvider].updateUser
			updateUser(payloadUpdate).then((response) => {
				if (response) {
					dispatch('LOAD_CURRENT_ACCOUNT')
					Vue.prototype.$notification.success({
						message: 'Actualización de datos',
						description: response.message,
					})
				} else {
					commit('SET_STATE', {
						loading: false,
					})
				}
			})
		},
		REQUEST_AUTHORIZATION({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Confirmando...',
			})
			return new Promise((resolve, reject) => {
				api
					.requestAuthorization(payload)
					.then((response) => {
						resolve(response)
					})
					.catch((error) => {
						reject(utilities.objectValidate(error, 'response.data.message', 'Contraseña inválida.'))
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
	},
	getters: {
		user: (state) => state,
		isUserAdmin: (state) => (!!state.is_admin ? true : false),
	},
}
