import Vue from 'vue'
import Vuex from 'vuex'
import * as dashboard from '@/services/dashboard'
import _ from 'lodash'
import utilities from '@/services/utilities'

const provider = {
	getPurchaseOrdersReport: dashboard.getPurchaseOrdersReport,
}

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		totales: {
			approved: 0,
			completed: 0,
			rejected: 0,
			waiting: 0,
		},
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET_PURCHASE_ORDERS_REPORT({ commit, dispatch, rootState }) {
			provider
				.getPurchaseOrdersReport()
				.then((response) => {
					if (response) {
						commit('SET_STATE', {
							totales: _.cloneDeep(response.data),
						})
					}
				})
				.catch((error) => {
					Vue.prototype.$notification.success({
						message: 'Error',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
	},
	getters: {
		totales: (state) => state.totales,
	},
}
