import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import * as api from '@/services/impressment'
import utilities from '@/services/utilities'
import Swal from 'sweetalert2'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		impressmentList: [],
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET({ commit }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información...',
			})
			api
				.get()
				.then((response) => {
					commit('SET_STATE', {
						impressmentList: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Swal.fire({
						title: 'Cotizaciones',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al consultar los registros. Intenta de nuevo.'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		CREATE({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Generando registro',
			})
			api
				.create(payload)
				.then((response) => {
					Swal.fire({
						title: 'Cotizaciones',
						text: utilities.objectValidate(response, 'message', 'Registro generado con éxito.'),
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						router.push(`/impressment`)
					})
				})
				.catch((error) => {
					Swal.fire({
						title: 'Cotizaciones',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al generar el registro. Intenta de nuevo.'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		UPDATE({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
			})
			api
				.update(payload)
				.then((response) => {
					console.log('response-->', response)
				})
				.catch((error) => {
					Swal.fire({
						title: 'Cotizaciones',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el registro. Intenta de nuevo.'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
	},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		impressmentList: (state) => state.impressmentList,
	},
}
