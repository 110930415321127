<template>
	<div :class="$style.breadcrumbs">
		<div :class="$style.path">
			<router-link to="/">Inicio</router-link>
			<template v-for="(item, index) in breadcrumb">
				<span v-if="index != 0" :key="index">
					<span :class="$style.arrow"></span>
					<span>{{ item.title }}</span>
				</span>
			</template>
			<span v-if="activeItem">
				<span :class="$style.arrow"></span>
				<strong :class="$style.current">{{ activeItem.title }}</strong>
			</span>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import utilities from '@/services/utilities'

export default {
	name: 'breadcrumbs',
	data() {
		return {
			breadcrumb: [],
			activeItem: {},
		}
	},
	computed: {
		...mapGetters('users', ['menu']),
	},
	methods: {
		getPath(data) {
			let items = [
				{
					title: 'Inicio',
				},
			]
			if (utilities.objectValidate(data, 'meta.parent', false)) {
				items.push({
					title: data.meta.parent,
				})
			}

			this.activeItem = {
				title: utilities.objectValidate(data, 'meta.title', ''),
			}
			return items
		},
	},
	mounted: function () {
		this.breadcrumb = this.getPath()
	},
	watch: {
		$route(to) {
			this.breadcrumb = this.getPath(to)
		},
	},
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
