import Vue from 'vue'

Vue.filter('replaceText', (value) => {
	if (!value) return ''
	value = value.toString()
	return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('replaceIfLogicalFalse', (value, textToReplace) => {
	return value ? value : textToReplace
})
