import utilities from '@/services/utilities'
import apiClient from '@/services/axios'
const Facturapi = require('facturapi')
const facturapi = new Facturapi(process.env.VUE_APP_FACTURAPI_API_KEY)

export async function searchProducts(q) {
	return new Promise((resolve, reject) => {
		return facturapi.catalogs
			.searchProducts({ q })
			.then((response) => {
				// console.log('services/facturapi::searchProducts::response-->', response)
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export async function searchUnits(q) {
	return new Promise((resolve, reject) => {
		return facturapi.catalogs
			.searchUnits({ q })
			.then((response) => {
				// console.log('services/facturapi::searchUnits::response-->', response)
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				// console.log('services/facturapi::searchUnits::err-->', err)
				reject(err)
			})
	})
}

export async function downloadPDF(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/billing/download/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
