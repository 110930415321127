import Vue from 'vue'
import Vuex from 'vuex'
import * as expenseTypes from '@/services/expense-types'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		expenseTypesList: [],
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET({ commit, dispatch, rootState }, globalLoader = false) {
			if (globalLoader) {
				commit(
					'SET_STATE',
					{
						spinnerLoader: true,
						spinnerLoaderLabel: 'Obteniendo información',
					},
					{ root: true },
				)
			} else {
				commit('SET_STATE', {
					spinnerLoader: true,
					spinnerLoaderLabel: 'Obteniendo información',
				})
			}
			return new Promise((resolve, reject) => {
				expenseTypes
					.get()
					.then((response) => {
						commit('SET_STATE', {
							expenseTypesList: response.data,
						})
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Tipo de gastos',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
						})
						commit('SET_STATE', {
							expenseTypesList: [],
						})
					})
					.finally(() => {
						if (globalLoader) {
							commit(
								'SET_STATE',
								{
									spinnerLoader: false,
								},
								{ root: true },
							)
						} else {
							commit('SET_STATE', {
								spinnerLoader: false,
							})
						}
					})
			})
		},
		CREATE({ commit, dispatch, rootState }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			return new Promise((resolve, reject) => {
				expenseTypes
					.create(payload)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: 'Tipos de gastos',
							description: response.message,
						})
						dispatch('GET')
						resolve(response)
					})
					.catch((error) => {
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		DISABLE({ commit, dispatch, rootState }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			expenseTypes
				.disable(id)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: 'Tipos de gastos',
						description: response.message,
					})
					dispatch('GET')
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Tipos de gastos',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al deshabilitar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		ENABLE({ commit, dispatch, rootState }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			expenseTypes
				.enable(id)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: 'Tipos de gastos',
						description: response.message,
					})
					dispatch('GET')
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Tipos de gastos',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al habilitar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		UPDATE({ commit, dispatch, rootState }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			return new Promise((resolve, reject) => {
				expenseTypes
					.update(payload)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: 'Tipos de gastos',
							description: response.message,
						})
						dispatch('GET')
						resolve(response)
					})
					.catch((error) => {
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
	},
	getters: {
		expenseTypesList: (state) => state.expenseTypesList,
	},
}
