import Vue from 'vue'
import Vuex from 'vuex'
import _ from 'lodash'
import router from '@/router'
import utilities from '@/services/utilities'
import * as apiQuotations from '@/services/quotations'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		quotationsList: [],
		actualQuotation: {
			quotationData: {},
			quotationElements: [],
		},
		downloadLink: '',
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET({ commit }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})
			apiQuotations
				.get()
				.then((response) => {
					commit('SET_STATE', {
						quotationsList: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Cotizaciones',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
					})
					commit('SET_STATE', {
						quotationsList: [],
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		GET_QUOTATION({ commit, state }, { id, onlyCopy }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})
			apiQuotations
				.getQuotation(id)
				.then((response) => {
					//
					if (onlyCopy) {
						commit('SET_STATE', {
							downloadLink: _.clone(response.data.url),
						})
						Vue.prototype.$notification.success({
							message: 'Cotizaciones',
							description: 'Link copiado al portapapeles',
						})
					} else {
						window.open(response.data.url)
					}
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Cotizaciones',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		async GET_ONE({ commit }, { id, redirect }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})

			return new Promise((resolve, reject) => {
				apiQuotations
					.getOne(id)
					.then((response) => {
						commit('SET_STATE', {
							actualQuotation: _.cloneDeep(response.data),
						})
						if (redirect) {
							router.push(`/quotations/${id}`)
						}
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Cotizaciones',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async CREATE({ commit, dispatch }, { payload, redirect }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})

			return new Promise((resolve, reject) => {
				apiQuotations
					.create(payload)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: 'Cotizaciones',
							description: response.message,
						})

						if (redirect) {
							router.push(`/quotations/quotations`)
						} else {
							dispatch('GET')
						}
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Cotizaciones',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al generar el registro'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		UPDATE({ commit, dispatch }, { id, payload }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})

			apiQuotations
				.update(payload)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: 'Cotizaciones',
						description: response.message,
					})
					dispatch('GET')
					resolve(response)
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Cotizaciones',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el registro'),
					})
					reject(error)
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
	},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		quotationsList: (state) => state.quotationsList,
		actualQuotation: (state) => state.actualQuotation,
		downloadLink: (state) => state.downloadLink,
	},
}
