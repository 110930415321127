import Vue from 'vue'
import Vuex from 'vuex'
import utilities from '@/services/utilities'
import Swal from 'sweetalert2'
import _ from 'lodash'
import * as facturapi from '@/services/facturapi'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {},
	getters: {},
}
