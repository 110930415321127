import apiClient from '@/services/axios'
import utilities from '@/services/utilities'

export async function purchaseOrders(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/reports/purchase-orders', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export async function sales(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/reports/sales', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				reject(err)
			})
	})
}
