import Vue from 'vue'
import Vuex from 'vuex'
import * as apiQuestionnaires from '@/services/questionnaires'
import utilities from '@/services/utilities'
import Swal from 'sweetalert2'
import _ from 'lodash'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		questionnairesList: [],
		actualRecord: {},
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		async GET({ commit }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Procesando información',
			})
			return new Promise((resolve, reject) => {
				apiQuestionnaires
					.getAll()
					.then(async (response) => {
						//
						commit('SET_STATE', {
							questionnairesList: _.cloneDeep(response.data),
						})
						resolve(response.data)
					})
					.catch((error) => {
						Swal.fire({
							title: 'Cuestionarios',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al consultar los registros.'),
							icon: 'error',
							confirmButtonText: 'Ok',
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async GET_ONE({ commit }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Procesando información',
			})
			return new Promise((resolve, reject) => {
				apiQuestionnaires
					.getOne(id)
					.then(async (response) => {
						//
						commit('SET_STATE', {
							actualRecord: _.cloneDeep(response.data),
						})
						resolve(response.data)
					})
					.catch((error) => {
						Swal.fire({
							title: 'Cuestionarios',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro.'),
							icon: 'error',
							confirmButtonText: 'Ok',
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
	},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		questionnairesList: (state) => state.questionnairesList,
		actualRecord: (state) => state.actualRecord,
	},
}
