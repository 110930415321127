import apiClient from '@/services/axios'
import utilities from '@/services/utilities'

export async function getSchedulesReport(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/schedules/report', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function createSchedule(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/schedules', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
