import Vue from 'vue'
import Vuex from 'vuex'
import * as paymentMethods from '@/services/payment-methods'
import _ from 'lodash'

const provider = {
	getPaymentMethods: paymentMethods.getPaymentMethods,
	getOnePaymentMethod: paymentMethods.getOnePaymentMethod,
	disablePaymentMethod: paymentMethods.disablePaymentMethod,
	enablePaymentMethod: paymentMethods.enablePaymentMethod,
}

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		paymentMethodsList: [],
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET({ commit, dispatch, rootState }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})
			provider
				.getPaymentMethods()
				.then((response) => {
					commit('SET_STATE', {
						paymentMethodsList: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Error',
						description: 'Error al obtener el listado de métodos de pago',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		GET_ONE({ commit, dispatch, rootState }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})
			provider
				.getOnePaymentMethod(id)
				.then((response) => {
					commit('SET_STATE', {
						paymentMethodsList: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Error',
						description: 'Error al obtener el registro de método de pago',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		DISABLE({ commit, dispatch, rootState }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			provider
				.disablePaymentMethod(id)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: 'Metodos de Pago',
						description: response.message,
					})
					dispatch('GET')
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Metodos de Pago',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al deshabilitar al usuario'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		ENABLE({ commit, dispatch, rootState }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			provider
				.enablePaymentMethod(id)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: 'Metodos de Pago',
						description: response.message,
					})
					dispatch('GET')
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Metodos de Pago',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al habilitar un usuario'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		CREATE({ commit, dispatch, rootState }, { payload }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})

			provider
				.createBrand(payload)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: 'Metodos de Pago',
						description: response.message,
					})
					dispatch('GET')
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Metodos de Pago',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al generar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
	},
	getters: {
		paymentMethods: (state) => state,
		paymentMethodsList: (state) => state.paymentMethodsList,
	},
}
