import Vue from 'vue'
import Vuex from 'vuex'
import * as users from '@/services/users'
import utilities from '@/services/utilities'
import Swal from 'sweetalert2'
import router from '@/router'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		usersList: [],
		actualUser: {},
		menu: {},
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET({ commit, dispatch, rootState }, globalLoader = false) {
			if (globalLoader) {
				commit(
					'SET_STATE',
					{
						spinnerLoader: true,
						spinnerLoaderLabel: 'Obteniendo información',
					},
					{ root: true },
				)
			} else {
				commit('SET_STATE', {
					spinnerLoader: true,
				})
			}
			return new Promise((resolve, reject) => {
				users
					.get()
					.then((response) => {
						commit('SET_STATE', {
							usersList: response.data,
							spinnerLoaderLabel: 'Obteniendo información',
						})
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Usuarios',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
						})
						commit('SET_STATE', {
							usersList: [],
						})
					})
					.finally(() => {
						if (globalLoader) {
							commit(
								'SET_STATE',
								{
									spinnerLoader: false,
								},
								{ root: true },
							)
						} else {
							commit('SET_STATE', {
								spinnerLoader: false,
							})
						}
					})
			})
		},
		async GET_ONE({ commit }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo datos',
			})
			return new Promise((resolve, reject) => {
				users
					.getOne(id)
					.then((response) => {
						commit('SET_STATE', {
							actualUser: utilities.objectValidate(response, 'data', {}),
						})
						resolve(response.data)
					})
					.catch((error) => {
						Swal.fire({
							title: 'Usuarios',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
							icon: 'error',
							confirmButtonText: 'Ok',
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		CREATE({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			users
				.create(payload)
				.then((response) => {
					// console.log('response-->', response)
					Swal.fire({
						title: 'Usuarios',
						text: utilities.objectValidate(response, 'message', 'Usuario generado de manera correcta.'),
						icon: 'success',
						confirmButtonText: 'Ok',
					})
						.then(() => {
							commit('SET_STATE', {
								actualUser: _.cloneDeep(response.data),
							})
							router.push(`/management/collaborators/${response.data.user_id}`)
						})
						.then(() => {
							commit('SET_STATE', {
								actualUser: {},
							})
						})
				})
				.catch((error) => {
					Swal.fire({
						title: 'Usuarios',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al generar el nuevo usuario'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		async UPDATE({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})

			return new Promise((resolve, reject) => {
				users
					.update(payload)
					.then((response) => {
						Swal.fire({
							title: 'Usuarios',
							text: utilities.objectValidate(response, 'message', 'Registro actualizado.'),
							icon: 'success',
							confirmButtonText: 'Ok',
						})
						resolve(response.data)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Usuarios',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el registro'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		DISABLE({ commit, dispatch, rootState }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			users
				.disable(id)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: 'Usuarios',
						description: response.message,
					})
					dispatch('GET')
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Usuario',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al deshabilitar al usuario'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		ENABLE({ commit, dispatch, rootState }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			users
				.enable(id)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: 'Usuarios',
						description: response.message,
					})
					dispatch('GET')
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Usuarios',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al habilitar un usuario'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		GET_MENU({ commit }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})
			users
				.getMenu()
				.then((response) => {
					commit('SET_STATE', {
						menu: response.data,
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Usuarios',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al obtener la información'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		UPDATE_PERMISSIONS({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			users
				.updatePermissions(payload)
				.then((response) => {
					Swal.fire({
						title: 'Usuarios',
						text: utilities.objectValidate(response, 'message', 'Información actualizada con éxito.'),
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						dispatch('GET_ONE', payload.user_id)
					})
				})
				.catch((error) => {
					Swal.fire({
						title: 'Usuarios',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar la información.'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
	},
	getters: {
		usersList: (state) => state.usersList,
		actualUser: (state) => state.actualUser,
		menu: (state) => state.menu,
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
	},
}
