import Vue from 'vue'

Vue.filter('lenLimit', (str, limit) => {
	// console.log('lenLimit', { str, limit })
	var response = ''
	var i
	var len

	for (i = 0, len = str.length; i < len; i += limit) {
		response += `${str.substr(i, limit)}<br />`
		//
	}
	return response
})
