import Vue from 'vue'
import Vuex from 'vuex'
import * as api from '@/services/complaints'
import utilities from '@/services/utilities'
import _ from 'lodash'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		complaintsList: [],
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET({ commit }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
				complaintsList: [],
			})
			return new Promise((resolve, reject) => {
				api
					.get()
					.then((response) => {
						commit('SET_STATE', {
							complaintsList: _.cloneDeep(response.data),
						})
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Quejas',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar los registros'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		CREATE({ commit, dispatch }, { payload, requireReload }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Generando registro',
			})
			return new Promise((resolve, reject) => {
				api
					.create(payload)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: 'Quejas',
							description: utilities.objectValidate(response, 'message', 'Queja generada con éxito'),
						})
						if (requireReload) {
							dispatch('GET')
						}
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Quejas',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al generar el registro'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		RESOLVE({ commit, dispatch }, { id, requireReload }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Generando registro',
			})
			return new Promise((resolve, reject) => {
				api
					.resolveComplaint(id)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: 'Quejas',
							description: utilities.objectValidate(response, 'message', 'Queja marcada como resuelta'),
						})
						if (requireReload) {
							dispatch('GET')
						}
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Quejas',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al generar el registro'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
	},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		complaintsList: (state) => state.complaintsList,
	},
}
