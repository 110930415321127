import Vue from 'vue'
import Vuex from 'vuex'
import * as api from '@/services/notifications'
import _ from 'lodash'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		notificationElements: [],
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET({ commit }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
				notificationElements: [],
			})

			api
				.getNotifications()
				.then((response) => {
					commit('SET_STATE', {
						notificationElements: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Error',
						description: 'Error al obtener el listado de notificaciones',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		DELETE({}, id) {
			api.deleteNotifications(id)
		},
	},
	getters: {
		notificationElements: (state) => state.notificationElements,
	},
}
