import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import * as api from '@/services/debtsToPay'
import utilities from '@/services/utilities'
import _ from 'lodash'
import Swal from 'sweetalert2'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		debtsToPayList: [],
		actualDebtToPay: {},
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET_ALL({ commit }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})
			api
				.getAll()
				.then((response) => {
					commit('SET_STATE', {
						debtsToPayList: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Cuentas por Pagar',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		GET({ commit }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})
			api
				.get(id)
				.then((response) => {
					commit('SET_STATE', {
						actualDebtToPay: _.cloneDeep(response.data),
					})
					router.push(`/operations/debts-to-pay/${id}`)
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Cuentas por Pagar',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		TOGGLE({ commit }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			api
				.toggle(id)
				.then((response) => {
					Swal.fire({
						title: 'Cuentas por Pagar',
						text: utilities.objectValidate(response, 'message', 'Registro actualizado con éxito'),
						icon: 'success',
						confirmButtonText: 'Ok',
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Cuentas por Pagar',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		UPDATE({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})

			api
				.update(payload)
				.then((response) => {
					Swal.fire({
						title: 'Cuentas por Pagar',
						text: utilities.objectValidate(response, 'message', 'Registro actualizado con éxito'),
						icon: 'success',
						confirmButtonText: 'Ok',
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Cuentas por Pagar',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
	},
	getters: {
		debtsToPayList: (state) => state.debtsToPayList,
		actualDebtToPay: (state) => state.actualDebtToPay,
	},
}
